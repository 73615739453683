<!--
 * @Author: Link
 * @Date: 2020-12-23 16:01:47
 * @LastEditTime: 2020-12-26 14:02:32
-->
<template>
  <div class="withdraw-detail">
    <topbar title="账单详情" background="#f9f9f9" />
    <img class="icon" src="@/assets/icon/money.png" />
    <div class="title">提现-{{ title }}</div>
    <div class="money">￥{{ data.applyAmount }}</div>
    <div class="detail">
      <div class="item">
        <div class="label">当前状态</div>
        <div class="value">
          <van-steps
            :active="step.active"
            direction="vertical"
            :active-color="payNotFail ? '#8BC34A' : '#FF5252'"
            :active-icon="payNotFail ? 'checked' : 'clear'"
          >
            <van-step
              >发起提现
              <div>{{ data.createTime | formatFullDate }}</div>
            </van-step>
            <van-step
              >处理中
              <div>{{ data.reviewTime | formatFullDate }}</div>
            </van-step>
            <van-step>
              {{
                data.paymentStatus === 4
                  ? '已拒绝'
                  : data.paymentStatus === 3
                  ? '提现失败'
                  : '已通过'
              }}
              <div>{{ data.paymentTransferTime | formatFullDate }}</div>
              <div v-if="data.paymentStatus === 3 || data.paymentStatus === 4">
                {{ data.paymentRemark }}
              </div>
            </van-step>
          </van-steps>
        </div>
      </div>
      <div class="item">
        <div class="label">提现金额</div>
        <div class="value">￥{{ fixNumber(data.applyAmount) }}</div>
      </div>
      <div class="item">
        <div class="label">账户余额</div>
        <div class="value">￥{{ fixNumber(data.currentTotalAmount - data.applyAmount) }}</div>
      </div>
      <div class="item">
        <div class="label">到账金额</div>
        <div class="value">￥{{ data.actualAmount }}</div>
      </div>
      <div class="item">
        <div class="label">提现手续费</div>
        <div class="value">￥{{ data.withdrawFee }}</div>
      </div>
      <div class="item">
        <div class="label">提现单号</div>
        <div class="value">{{ data.id }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Step, Steps } from 'vant'
export default {
  name: 'withdrawDetail',
  data() {
    return {
      step: {
        active: 0
      },
      data: {},
      paymentStatus: ['待审核', '处理中', '提现成功', '提现失败', '已拒绝']
    }
  },
  computed: {
    title() {
      let no = this.data.accountNo
      // let lastFourNumber = no.substr(no.length - 4, 4)
      if (this.data.accountType == 1) {
        return `银行卡（${no}）`
      } else if (this.data.accountType == 2) {
        return `支付宝（${no}）`
      } else {
        return '微信零钱'
      }
    },
    // 体现没有失败
    payNotFail() {
      return !(this.data.paymentStatus === 3 || this.data.paymentStatus === 4)
    }
  },
  created() {
    this.getData()
  },
  methods: {
    fixNumber(num) {
      return Number(num).toFixed(2)
    },
    getData() {
      this.$http
        .get('boom-center-user-service/app/distribution/withdraw-records/' + this.$route.query.id)
        .then(res => {
          this.data = res || {}
          this.step.active = res.applyStatus >= 1 ? 2 : res.applyStatus
        })
    }
  }
}
</script>

<style lang="less" scoped>
.withdraw-detail {
  text-align: center;
  .icon {
    width: 48px;
    height: 48px;
    margin-top: 60px;
  }
  .title {
    margin-top: 12px;
    height: 24px;
    font-size: 17px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
  .money {
    margin-top: 10px;
    height: 35px;
    font-size: 30px;
    font-weight: 500;
    color: #1b1d21;
    line-height: 35px;
  }
  .detail {
    margin-top: 10px;
    width: 100%;
    text-align: left;
    padding: 30px;

    .item {
      display: flex;
      margin-top: 10px;
      .label {
        width: 100px;
        overflow: hidden;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        line-height: 16px;
      }
      .value {
        flex: 1;
        overflow: hidden;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
      }
    }
    .item:nth-child(1) {
      .label {
        height: 50px;
        line-height: 37px;
        width: 92px;
      }
    }
  }
}
</style>
